import React, { Fragment } from 'react';
import get from 'lodash/get';
import { GridRow } from '@src/theme/Global.styled';
import { Grid } from '@src/components/Grid';
import { IconLink } from '@src/components/Buttons';
import { IETText } from '@src/models/IETText';

interface FAQHeaderTitleProps {
  block: IETText;
}

const FAQHeaderTitle = (props: FAQHeaderTitleProps) => {
  const fields = get(props, 'block.fields');
  const title = get(fields, 'title');
  const content = get(fields, 'content');
  return (
    <Grid
      size="m"
      className="px-0"
      data-block-type={get(props, 'block.system.contentType', '')}
    >
      <GridRow>
        {title && <h1>{title}</h1>}
        {content && <p>{content}</p>}
        {Boolean(fields?.buttons?.length) &&
          fields.buttons.map((button, buttonIndex) => (
            <Fragment key={`links-index-${buttonIndex}`}>
              <IconLink
                type="page"
                href={get(button, 'url', '#') || '#'}
                target={get(button, 'target', '_self') || '_self'}
              >
                {button?.name}
              </IconLink>
              {buttonIndex !== fields.buttons.length - 1 && <br />}
            </Fragment>
          ))}
      </GridRow>
    </Grid>
  );
};

export default FAQHeaderTitle;
